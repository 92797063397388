import { HeadFC } from "gatsby";
import React, { useEffect } from "react";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";

const TravelExperience: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/5b2tzdENS59mmJDnYryjmn/02c62401014a0c5a916c1efc11f454ca/travel-experience-mountains.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Travel</h1>
                  <p className="secondary-text">Bespoke luxury experiences</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">See availability</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Experience details</h2>

               <div className="event-details-container">
                  <div className="event-details-item">
                     <span>🗓</span>
                     <p>Year-round availability</p>
                  </div>

                  <div className="event-details-item">
                     <span>🌍</span>
                     <p>Worldwide</p>
                  </div>

                  <div className="event-details-item">
                     <span>✈️</span>
                     <p>Various travel options</p>
                  </div>

                  <div className="event-details-item">
                     <span>💵</span>
                     <p>Pricing options</p>
                  </div>
               </div>

               <div className="event-summary-wrapper">
                  <p><strong>Discover the world in style with our exclusive travel service, catering to discerning travelers seeking exceptional experiences. From luxurious destinations to unique cultural encounters, we offer personalized travel plans tailored to your interests and preferences.</strong></p>

                  <p>With a team of travel experts, we'll help you explore the world in comfort and elegance, creating memories that will last a lifetime. From private jets and five-star accommodations to insider access to the world's most sought-after sights, our travel plans are designed to exceed your expectations and provide an unforgettable experience.</p>

                  <p>Indulge in the ultimate in luxury and comfort as you visit the world's most beautiful destinations, enjoy world-class dining, and experience local cultures like never before. Whether you’re seeking adventure, relaxation, or a unique cultural experience, our travel experts will work with you to create the perfect itinerary for your next trip.</p>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventName="Travel planning availability"
            eventImage="https://images.ctfassets.net/welt89o8b674/73qbm00i9X5fuavCWyrVUZ/9a7b59f05d3b330daca3e50c77838c88/travel-desert.jpg"
         />

         <SomethingElseCta/>

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title={`Travel | Prestige VIP`}
      metaDescription={`Travel in the most luxurious way with our managed travel experience through Prestige VIP. International availabilty with a variety of options.`}
      slug="/experiences/travel"
   />
)

export default TravelExperience